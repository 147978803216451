#changePassDiv {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
}
#changePassDiv input {
    padding: 20px 20px;
    display: flex;
    flex: 1;
    background-color: #e1e1e16c;
    border: 1px solid #c5c5c5;
}
#changePassDiv .line {
    margin: 20px 10px;
    border-bottom: 0px solid #ccc;
    width: calc(100% - 20px);
    background-color: rgb(190, 190, 190);
    height: 1px;
}
#userList {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    padding: 20px;
    border: 0px solid #ccc;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
}
.modal {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;
    padding: 20px;
    /* background-color: rgba(255, 0, 0, 0.251); */
}
.modalContainer {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    display: flex;
    z-index: 999;
}
.AddUserBtn {
    padding: 10px 20px;
    border-radius: 16px;
    border: 1px solid gainsboro;
}
