.Row {
    flex-direction: row;
    display: flex;
    gap: 20px;
}
p {
    margin: 0;
}
#DateSelect {
    max-width: 96%;
    display: flex;
    flex-direction: row;
    height: 80px;
    background-color: rgb(255, 255, 255);
    /* justify-content: center; */
    align-items: center;
    padding: 0 20px;
}
#DateSelect select {
    height: 40px;
    border: 0;
    border-radius: 12px;
    margin-right: 20%;
    margin-left: 20px;
    background-color: #9898981a;
    padding: 0 5px;
}
#DateSelect div {
    margin-left: 20%;
    gap: 5px;
    display: flex;
}
#totalSalesReport {
    background-color: rgb(255, 255, 255);
    min-height: 100px;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px #0000001a;
    display: flex;
    flex-direction: column;
}
.reportContainer {
    background-color: #8d8d8d1a;
    border-radius: 12px;
    min-width: 125px;
    padding: 10px 20px;
    margin-right: 20;
    border: 1px solid #0000001a;
}

.reportContainer p {
    color: #696969;
    font-size: 12px;
    font-weight: 600;
    margin: 0 0 5px 0;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
}
.reportContainer span {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
}
input {
    padding: 10px 5px;
    border: 1px solid rgb(184, 184, 184);
    background-color: #ffffff1a;
    border-radius: 12px;
    color: rgb(103, 103, 103);
}
input:focus {
    outline: 1px solid #aaa;
}
textarea:focus {
    outline: 1px solid #aaa;
}
#customDate {
    align-items: center;
}
.Container {
    background-color: white;
    width: 100%;
    padding: 10px;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    gap: 10px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px #0000001a;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif;
}
.dot {
    border-radius: 50%;
    min-width: 10px;
    padding: 5px;
    min-height: 10px;
}
#topProducts {
    background-color: rgb(255, 255, 255);
    height: 300px;
    display: flex;
    flex: 1;
    overflow-x: hidden;
    flex-direction: column;
    margin: 20px 20px;
    border-radius: 4px;
}
#firstContainer {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 20px;
}
#firstContainer #yearlyReport {
    display: flex;
    flex: 1;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    gap: 10px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px #0000001a;
    display: flex;
    flex-direction: column;
}
#firstContainer #sourceReport {
    display: flex;
    flex: 0.3;
    justify-content: space-around;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    gap: 10px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px #0000001a;
}
.radio-button-container {
    display: flex;
    align-items: center;
    gap: 24px;
}

.radio-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.radio-button__input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.radio-button__label {
    display: inline-block;
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;
    font-size: 15px;
    color: #aaa;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.3s ease;
}

.radio-button__custom {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #555;
    transition: all 0.3s ease;
}

.radio-button__input:checked + .radio-button__label .radio-button__custom {
    background-color: #4c8bf5;
    border-color: transparent;
    transform: scale(0.8);
    box-shadow: none;
}

.radio-button__input:checked + .radio-button__label {
    color: #4c8bf5;
}

.radio-button__label:hover .radio-button__custom {
    transform: scale(1.2);
    border-color: #4c8bf5;
    box-shadow: 0 0 20px #4c8bf580;
}
.statCard {
    flex: 1;
    background-color: #eeeeee8b;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 2px;
    min-width: 160px;
    max-width: 160px;
}

.refreshButton {
    color: white;
    background-color: #ffef03;
    font-weight: 500;
    border-radius: 0.5rem;
    font-size: 1rem;
    line-height: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    cursor: pointer;
    text-align: center;
    margin-right: 0.5rem;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #e3d400;
    justify-content: center;
    align-items: center;
}

.refreshButton:hover {
    background-color: #ffef03;
}

.refreshButton svg {
    display: inline;
    width: 1.3rem;
    height: 1.3rem;
    color: black;
}

.refreshButton:focus svg {
    animation: spin_357 0.5s linear;
}

@keyframes spin_357 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.MuiDrawer-root div {
    border-right: none !important;
}
