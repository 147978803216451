#filterDiv {
    display: flex;
    margin: 20px 20px;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    gap: 70px;
    justify-content: space-between;
}

#selectOptions {
    display: flex;
    flex: 6;
    gap: 10px 20px;
    flex-wrap: wrap;
}

#selectOptions select {
    width: fit-content;
    height: 40px;
    border-radius: 12px;
    border: 1px solid rgb(193, 193, 193);
    padding: 5px;
}

#selectOptions input {
    width: 300px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid rgb(193, 193, 193);
    padding: 5px;
    box-sizing: border-box;
}

#searchBtns {
    display: flex;
    flex: 4;
    gap: 10px 20px;
    flex-wrap: wrap;
    justify-content: right;
}

#searchBtns button {
    width: 150px;
    height: 40px;
    border-radius: 12px;
    font-size: 16px;
}

.searchBtn {
    border: 1px solid #dacc00;
    background-color: #ffef03;
}

.clearBtn {
    background-color: #e21b1b1a;
    border: 1px solid #e21b1b;
}

.importBtn {
    background-color: #0d9e671a;
    border: 1px solid #0d9e67;
}

.exportBtn {
    border: 1px solid rgb(175, 175, 175);
}

#ProductListContainer {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    margin: 20px;
    height: fit-content;
    flex-direction: column;
}
/* Proper alignment for the Product Name column */
.product-name-header {
    width: 200px; /* Adjust width for better visibility */
    text-align: left;
}

#productList {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.productImage {
    width: 50px;
    height: 50px;
}

#productList input {
    width: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(236 236 236 / 50%);
    color: black;
}

#productList td {
    padding: 0;
}

.DisablePinModal {
    background-color: #e21b1b1a;
    /* width: 40px; */
    /* height: 40px; */
    padding: 10px 20px;
    border-radius: 16px;
    border: 0;
}

.SaveBtn {
    background-color: #0d9e67;
    border: 0;
    color: white;
    border-radius: 16px;
    padding: 10px 20px;
}

.pinCheckBox {
    padding: 10px 20px;
    background-color: rgb(242, 242, 242);
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 25px;
}

.pinCheckBox input {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    /* border-color: aliceble; */
    border: 1px solid gray;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    overflow: hidden;
    padding: 5% 10%;
    /* display: flex; */
}
.selectBtn {
    background-color: transparent;
    border-radius: 20px;
    margin: 10px;
    padding: 10px 20px;
    border: 1px solid rgb(206, 206, 206);
}

.modal-open {
    overflow: hidden;
}

#selectOptions option {
    text-transform: capitalize;
}
#selectOptions select {
    text-transform: capitalize;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    text-align: center;
    padding: 10px;
    font-weight: bold;
    border-bottom: 2px solid #ddd;}

    td{
        padding: 10px;
        text-align: center;
        vertical-align: middle;
    } 
    
td:nth-child(3) {
    max-width: 150px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

td:nth-child(n+4) { /* Attribute se aage wale sab columns */
    text-align: center;
}

.action-column {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px; /* Spacing between buttons */
    }
    
/* Action Buttons */
.action-column button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    font-size: 14px;
}