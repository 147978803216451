.servingAreaRootContainer {
    display: flex;
    flex: 1;
    padding: 20px;
    gap: 20px;
}
.servingAreaContainer {
    display: flex;
    flex: 1;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    overflow-y: scroll;
    flex-direction: column;
}
.cityContainer {
    display: flex;
    background-color: white;
    flex: 1;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    overflow-y: scroll;
}
.cityListItem {
    display: flex;
    flex: 1;
    border: 1px solid #eee;
    margin: 10px 0px;
    padding: 10px 20px;
    border-radius: 8px;
    justify-content: space-between;
}
