#PhoneDisplayContainer {
    display: flex;
    overflow: scroll;
    height: 50px;
}

.AddCardDiv {
    gap: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    padding: 20px;
}
.AddCardDiv .inputBoxDiv {
    gap: 10px;
    display: flex;
    align-items: center;
}
.AddCardDiv .inputDiv {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    input {
        flex: 1;
    }
    label {
        width: 80px;
    }
}
.AddCardDiv div {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
}
.AddCardDiv input {
    padding: 10px 12px;
}
.AddCardDiv select {
    padding: 10px 12px;
    border: 1px solid #ccc;
    border-radius: 12px;
}
