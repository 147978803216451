#orderDetailsRootContainer {
    display: flex;
    flex-direction: column;
}

#topBtnsDiv {
    display: flex;
    flex: 1;
    margin: 10px 20px;
    padding: 0px;
    background-color: transparent;
    /* height: 50px; */
    justify-content: space-between;
    align-items: center;
}

#topBtnsDiv button {
    border: 0;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
    background-color: white;
    border-radius: 12px;
    padding: 12px 15px 12px 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

#issueDetailContainer {
    display: flex;
    flex: 1;
    margin: 5px 20px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
}
#orderDetailsRootContainer #orderDetailsContainer {
    display: flex;
    flex: 1;
    margin: 5px 20px;
    background-color: white;
    height: 300px;
    border-radius: 12px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
}

#sellerDetails {
    display: flex;
    padding: 20px;
    flex: 0.5;
    flex-direction: column;
}

#orderDetails {
    flex-direction: column;
    display: flex;
    flex: 0.5;
    padding: 20px;
}

h2 {
    margin: 5px 0px;
    text-transform: capitalize;
    font-weight: 600;
}

.line {
    height: 1px;
    background-color: rgb(216, 216, 216);
    display: flex;
    margin: 5px 0% 5px 0;
}

h4 {
    margin: 5px 0px;
    text-transform: capitalize;
    font-weight: 700;
}

span {
    font-weight: 400;
    margin: 5px 5px 5px 0;
    text-transform: capitalize;
    color: rgb(70, 70, 70);
}

#dateDiv {
    display: flex;
    flex: 1;
    margin: 10px 20px;
    background-color: white;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    padding: 10px 20px;
    padding-right: 80px;
    border-radius: 12px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
}

#orderDetailsRootContainer #productList {
    background-color: white;
    margin: 10px 20px;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.126);
}

.updateOrderTable {
    border-collapse: collapse;
    border: 1px solid black;
    tr,
    th,
    td {
        padding: 10px 20px;
        border: 1px solid rgb(0, 0, 0);
    }
}
