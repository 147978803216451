.table-row {
    border: 1px solid #000;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

td {
    text-align: left;
}

.promocodeContainer {
    /* border-collapse: collapse;  */
    border-spacing: 0 1em;

    thead {
        background-color: #ddd;

        th {
            padding: 10px 0;
        }
    }

    tbody {
        /* gap: 10 */
        tr {
            /* border: 1px solid rgb(7, 7, 7); */
            border-radius: 20px;
            /* overflow: hidden; */
            box-shadow: 2px 2px 5px 0 #0000001a;

            /* margin: 10px; */
            td {
                /* border: 1px solid rgb(7, 7, 7); */
                text-align: center;
                padding: 20px 10px;
            }
        }
    }
}

.editModalContainer {
    z-index: 9991;
    display: flex;
    position: fixed;
    min-height: 100vh;
    /* min-width:100vw; */
    width: calc(100%);
    max-width: 100vw;
    left: 0;
    top: 0;
    flex: 1;
    background-color: rgba(0, 0, 0, 0.5);

    .editModal {
        background-color: #fff;
        margin-top: 20px;
        margin-bottom: 20px;
        margin: 20px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        width: 80%;
        display: flex;
        flex: 1;
        flex-direction: column;
        /* max-width: 700px; */

        .editModalHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .editModalTitle {
                font-size: 20px;
                font-weight: 600;
            }

            .closeIcon {
                cursor: pointer;
                background-color: transparent;
                border: none;
                font-size: 32px;
            }
        }

        .editModalBody {
            height: calc(100vh - 150px);
            overflow-y: scroll;
            padding: 5px;
            overflow-x: hidden;
            .form-group-container {
                display: flex;
                flex: 1;
                gap: 10px;
            }
            .form-group {
                margin-bottom: 20px;
                width: 100%;
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 3px;
                label {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                input {
                    width: calc(100% - 20px);
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    /* accent-color: #ffee08; */
                }

                input:focus {
                    border: 2px solid #ffee08;
                    outline-width: 0;
                }

                textarea {
                    width: calc(100% - 20px);
                    padding: 10px;
                    border: 1px solid #ddd;
                    accent-color: #ffee08;
                    border-radius: 8px;
                }
                select {
                    background-color: white;
                    -webkit-appearance: none;
                    padding: 10px;
                }
            }
        }

        .editModalFooter {
            display: flex;
            justify-content: flex-end;
            gap: 50px;

            button {
                padding: 10px 20px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }

            .btn-primary {
                background-color: #ffee08;
                color: #000;
            }

            .btn-secondary {
                background-color: #fff;
                color: #000;
                border: 1px solid #ffee08;
            }
        }
    }
}
