.tagListContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding: 18px;
    overflow-y: scroll;
    height: calc(100vh - 140px);
    .tagListItem {
        padding: 10px 20px;
        border: 1px solid #eee;
        margin: 2px;
        border-radius: 8px;
        background-color: white;
        text-align: left;
        display: flex;
        flex: 1;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
    }
}
.tagEditorContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    padding: 18px;
}
